import React, { useState, useEffect, useContext } from 'react';
import { Criteo } from '~/components/Criteo/Criteo';
import SEO from '~/components/seo';
import { useMediaQuery } from 'react-responsive';
import Cookies from 'js-cookie';
import { Link } from 'gatsby';
import Image from 'gatsby-image';
import { css } from 'styled-components';

import { size } from '~/constants/Breakpoint';
import { useBackground } from '../../../hooks/use-background';
import { months, getImage, getShopifyImageTransformedSrc } from '~/helpers';
import StoreContext from '~/layouts/StoreContext';
import { DeriveHeaderClasses } from '~/components/Navigation';
import { getCustomerOrderById } from '~/helpers/requests/customer-requests';
import { Heading, Subheading, BodyText, BodyTextAlt } from '~/components/.base/headings';
import { FlexBox, Grid, Row, Col } from '~/components/.base/containers';
import { RedButton, WhiteButton } from '~/components/.base/buttons';
import { BackgroundDiv, PageContainerEcomm } from '~/components/.base/containers';
import LogOutIcon from '~/images/icons/accountpage/logout.svg';
import BackIcon from '~/images/icons/accountpage/back.svg';
import CartIcon from '~/images/icons/cart.svg';
import '../account.scss';
import './orders.scss';

const OrdersPage = ({ location }) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${size.lg - 1}px)` });
  const backgroundImages = useBackground().edges;

  const [orderInfoOpen, setOrderInfoOpen] = useState(false);
  const [orderData, setOrderData] = useState({});
  const {
    openCart,
    removeLineItem,
    addVariantToCart,
    store: {
      client,
      checkout,
      checkout: { lineItems },
    },
  } = useContext(StoreContext);
  useEffect(() => {
    const accessToken = Cookies.get('KEPT_SESS');
    if (!accessToken) window.location.href = '/account/login';
  }, []);
  const logOut = () => {
    Cookies.remove('KEPT_SESS');
    window.location.href = '/';
  };
  const reOrder = async () => {
    for (let item of lineItems) {
      await removeLineItem(client, checkout.id, item.id);
    }
    for (let item of orderData.order.lineItems.edges) {
      await addVariantToCart(btoa(item.node.variant.id), item.node.quantity, []);
    }
    openCart();
  };
  const formatDate = d => {
    return `${months[d.getMonth()]} ${d.getDate()}, ${d.getFullYear()}`;
  };
  useEffect(() => {
    if (window) {
      const params = new URLSearchParams(window.location.search);
      const orderId = params.get('order_id');
      const formattedId = orderId.replace('gid://shopify/Order/', '').split('?key')[0];
      getCustomerOrderById(formattedId).then(res => {
        if (res.data.data) {
          setOrderData(res.data.data);
        } else {
          setOrderData({ message: 'no order' });
        }
      });
    }
  }, []);

  return (
    <PageContainerEcomm className={`account account-orders ${isMobile ? 'mobile' : ''}`}>
      <BackgroundDiv
        left="50px"
        top="100px"
        z="-1"
        css={css`
          width: 624px;
          max-width: 100%;
        `}
      >
        <Image fluid={getImage(backgroundImages, 'blurred_smudge_2')} alt="" />
      </BackgroundDiv>
      <SEO title="View Order" />
      <Criteo />
      <DeriveHeaderClasses location={location} />
      <header className="header">
        <FlexBox justify="center" className="header-items">
          <div className="back-btn">
            <WhiteButton
              as={Link}
              to="/account/dashboard"
              className="no-stroke"
              w="40px"
              h="40px"
              pd="0"
            >
              <BackIcon style={{ transform: 'rotate(90deg)' }} />
            </WhiteButton>
            <BodyTextAlt md="0 0 0 .75rem">BACK TO my ACCOUNT</BodyTextAlt>
          </div>
          <Heading md="0" align="center">
            <span className="red">Order {orderData?.order?.name || ''}</span>
          </Heading>
          <FlexBox justify="center" align="center" onClick={logOut} className="log-out">
            <BodyTextAlt md="0 .75rem 0 0">Logout</BodyTextAlt>
            <WhiteButton className="no-stroke" w="40px" h="40px" pd="0">
              <LogOutIcon />
            </WhiteButton>
          </FlexBox>
        </FlexBox>
      </header>
      <div className="orders-main">
        {orderData?.order && (
          <>
            {orderInfoOpen && (
              <FlexBox justify="space-between" className="order-info">
                <span>
                  Your order has been sent. Track the shipment with number 1234123412341234 or by
                  clicking here: www.loremipsum.com/go/trackconfirmation
                </span>
                <button className="close" onClick={() => setOrderInfoOpen(false)}>
                  &times;
                </button>
              </FlexBox>
            )}
            <Row>
              <Col pd="1.5rem 0" size={4}>
                <div className="desktop">
                  <FlexBox justify="space-between">
                    <div>
                      <BodyTextAlt md="0 0 .5rem">Date</BodyTextAlt>
                      <Heading>
                        <span className="red">
                          {formatDate(new Date(orderData.order.createdAt))}
                        </span>
                      </Heading>
                    </div>
                    <div>
                      <BodyTextAlt md="0 0 .5rem">Payment Status</BodyTextAlt>
                      <div className="bubble green">authorized</div>
                    </div>
                    <div>
                      <BodyTextAlt md="0 0 .5rem">Fulfillment Status</BodyTextAlt>
                      <div
                        className={`bubble ${
                          orderData.order.displayFulfillmentStatus === 'UNFULFILLED'
                            ? 'yellow'
                            : 'green'
                        }`}
                      >
                        {orderData.order.displayFulfillmentStatus}
                      </div>
                    </div>
                    <FlexBox align="center" className="reorder" onClick={reOrder}>
                      <BodyTextAlt md="0 .75rem 0 0">Reorder</BodyTextAlt>
                      <RedButton pd="0" w="40px" h="40px">
                        <CartIcon style={{ margin: '0' }} />
                      </RedButton>
                    </FlexBox>
                  </FlexBox>
                </div>
                <div className="mobile">
                  <Row className="order-properties-upper">
                    <Col size={1}>
                      <BodyTextAlt md="0 0 .5rem">Date</BodyTextAlt>
                      <Heading>
                        <span className="red">
                          {formatDate(new Date(orderData.order.createdAt))}
                        </span>
                      </Heading>
                    </Col>
                    <Col size={1}>
                      <FlexBox
                        align="center"
                        justify="flex-end"
                        className="reorder"
                        onClick={reOrder}
                      >
                        <BodyTextAlt md="0 .75rem 0 0">Reorder</BodyTextAlt>
                        <RedButton pd="0" w="40px" h="40px">
                          <CartIcon style={{ margin: '0' }} />
                        </RedButton>
                      </FlexBox>
                    </Col>
                  </Row>
                  <Row className="order-properties-lower">
                    <Col size={1}>
                      <div>
                        <BodyTextAlt md="0 0 .5rem">Payment Status</BodyTextAlt>
                        <div className="bubble green">authorized</div>
                      </div>
                    </Col>
                    <Col size={1}>
                      <div>
                        <BodyTextAlt md="0 0 .5rem">Fulfillment Status</BodyTextAlt>
                        <div
                          className={`bubble ${
                            orderData.order.displayFulfillmentStatus === 'UNFULFILLED'
                              ? 'yellow'
                              : 'green'
                          }`}
                        >
                          {orderData.order.displayFulfillmentStatus}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="desktop">
                  <Row className="table-titles">
                    <Col size={5}>
                      <BodyTextAlt md="0 0 0 30px">Product</BodyTextAlt>
                    </Col>
                    <Col size={1}>
                      <BodyTextAlt>Quantity</BodyTextAlt>
                    </Col>
                    <Col size={1} className="tr">
                      <BodyTextAlt>Total</BodyTextAlt>
                    </Col>
                  </Row>
                  <Grid className="line-items">
                    {orderData.order.lineItems.edges.map((item, i) => (
                      <Row className="item" key={i}>
                        <Col size={5}>
                          <FlexBox align="center">
                            <div className="image-cont">
                              {item.node.image ? (
                                <img
                                  src={getShopifyImageTransformedSrc(
                                    item.node.image.originalSrc,
                                    '_40x'
                                  )}
                                  alt={item.node.image.altText}
                                />
                              ) : null}
                            </div>
                            <BodyText md="0">
                              {item.node.product ? item.node.product.title : 'Unknown Product'}
                            </BodyText>
                          </FlexBox>
                        </Col>
                        <Col size={1}>
                          <BodyText md="0">
                            {item.node.quantity < 10
                              ? `0${item.node.quantity}`
                              : item.node.quantity}
                          </BodyText>
                        </Col>
                        <Col size={1} className="tr">
                          <BodyText md="0">
                            ${Number(item.node.originalTotalSet.presentmentMoney.amount).toFixed(2)}
                          </BodyText>
                        </Col>
                      </Row>
                    ))}
                  </Grid>
                </div>
                <div className="mobile">
                  <div className="line-items mobile">
                    {orderData.order.lineItems.edges.map((item, i) => {
                      return (
                        <Grid className="item" key={i}>
                          <FlexBox align="center" className="upper">
                            <div className="image-cont">
                              {item.node.image ? (
                                <img
                                  src={getShopifyImageTransformedSrc(
                                    item.node.image.originalSrc,
                                    '_60x'
                                  )}
                                  alt={item.node.image.altText}
                                />
                              ) : null}
                            </div>
                            <Heading md="0">
                              {item.node.product ? item.node.product.title : 'Unknown Product'}
                            </Heading>
                          </FlexBox>
                          <Row className="lower">
                            <Col size={1}>
                              <BodyTextAlt md="0 0 5px">Quantity</BodyTextAlt>
                              <BodyTextAlt color="#1d1d1d" md="0">
                                {item.node.quantity < 10
                                  ? `0${item.node.quantity}`
                                  : item.node.quantity}
                              </BodyTextAlt>
                            </Col>
                            <Col size={1} className="tr">
                              <BodyTextAlt md="0 0 5px">Total</BodyTextAlt>
                              <BodyTextAlt color="#1d1d1d" md="0">
                                $
                                {Number(item.node.originalTotalSet.presentmentMoney.amount).toFixed(
                                  2
                                )}
                              </BodyTextAlt>
                            </Col>
                          </Row>
                        </Grid>
                      );
                    })}
                  </div>
                </div>
                <div className="totals">
                  <FlexBox justify="space-between">
                    <BodyText md=".25rem 0">Subtotal</BodyText>
                    <BodyText md=".25rem 0">
                      <span className="red">
                        $
                        {Number(orderData.order.subtotalPriceSet.presentmentMoney.amount).toFixed(
                          2
                        )}
                      </span>
                    </BodyText>
                  </FlexBox>
                  <FlexBox justify="space-between">
                    <BodyText md=".25rem 0">Shipping</BodyText>
                    <BodyText md=".25rem 0">
                      <span className="red">
                        $
                        {Number(
                          orderData.order.totalShippingPriceSet.presentmentMoney.amount
                        ).toFixed(2)}
                      </span>
                    </BodyText>
                  </FlexBox>
                  <FlexBox justify="space-between">
                    <BodyText md=".25rem 0">Taxes</BodyText>
                    <BodyText md=".25rem 0">
                      <span className="red">
                        ${Number(orderData.order.totalTaxSet.presentmentMoney.amount).toFixed(2)}
                      </span>
                    </BodyText>
                  </FlexBox>
                </div>
                <div className="totals grand-total">
                  <FlexBox justify="space-between">
                    <Heading md=".25rem 0">Total</Heading>
                    <Heading md=".25rem 0">
                      <span className="red">
                        ${Number(orderData.order.totalPriceSet.presentmentMoney.amount).toFixed(2)}
                      </span>
                    </Heading>
                  </FlexBox>
                </div>
              </Col>
              <Col pd="1.5rem 0 0 40px" size={1}>
                {orderData?.order?.shippingAddress && (
                  <div className="address">
                    <Subheading>Shipping Address</Subheading>
                    <BodyText md="15px 0 37px">
                      {orderData.order.shippingAddress.name}
                      <br />
                      {orderData.order.shippingAddress.address1}
                      <br />
                      {orderData.order.shippingAddress.city},{' '}
                      {orderData.order.shippingAddress.provinceCodeV2}{' '}
                      {orderData.order.shippingAddress.zip}
                      <br />
                      {orderData.order.shippingAddress.country}
                    </BodyText>
                  </div>
                )}
                <div className="address">
                  <Subheading>Billing Address</Subheading>
                  {orderData?.order?.billingAddress ? (
                    <BodyText md="0 0 37px">
                      {orderData.order.billingAddress.name}
                      <br />
                      {orderData.order.billingAddress.address1}
                      <br />
                      {orderData.order.billingAddress.city},{' '}
                      {orderData.order.billingAddress.provinceCodeV2}{' '}
                      {orderData.order.billingAddress.zip}
                      <br />
                      {orderData.order.billingAddress.country}
                    </BodyText>
                  ) : (
                    orderData?.order?.shippingAddress && (
                      <BodyText md="0 0 37px">
                        {orderData.order.shippingAddress.name}
                        <br />
                        {orderData.order.shippingAddress.address1}
                        <br />
                        {orderData.order.shippingAddress.city},{' '}
                        {orderData.order.shippingAddress.provinceCodeV2}{' '}
                        {orderData.order.shippingAddress.zip}
                        <br />
                        {orderData.order.shippingAddress.country}
                      </BodyText>
                    )
                  )}
                </div>
              </Col>
            </Row>
          </>
        )}
        {orderData?.message === 'no order' && <BodyText>No order found.</BodyText>}
      </div>
    </PageContainerEcomm>
  );
};

export default OrdersPage;
